import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE,HEADER_COLOR_LIGHT } from '../constants/Theme';

export const APP_NAME = 'Rebeca';

export const THEME_CONFIG = {
    navCollapsed: false,
    sideNavTheme: SIDE_NAV_LIGHT,
    locale: 'en',
    navType: NAV_TYPE_SIDE,
    topNavColor: '#3e82f7',
    headerNavColor: '#ffffff',
    mobileNav: false,
    headerColor: HEADER_COLOR_LIGHT,
    appLocation : 'app/views/home',
    scrollDown:false,
    activationCodeValidated:true,
};
