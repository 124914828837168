import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import moment from 'moment';
import {
    toggleCollapsedNav,
    onMobileNavToggle,
    setScrollDown,
    onActivationCodeValidatedToggle
} from '../../redux/actions';
import {Menu, Layout, Button, Row, Col, Drawer, Modal, Input, message} from "antd";
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH, HEADER_COLOR_LIGHT } from '../../constants/Theme';
import utils from '../../utils'
import logo from '../../assets/images/logo.png';
import logo_dark from '../../assets/images/logo_black.png';
import Flex from "../shared-components/Flex";
import {Link, useHistory} from "react-router-dom";
import {animateScroll as scroll, scroller} from 'react-scroll'
const { Header } = Layout;


export const HeaderNav = props => {
    const { navCollapsed, mobileNav, navType, headerNavColor, toggleCollapsedNav, onMobileNavToggle, isMobile, headerColor,appLocation ,setScrollDown,onActivationCodeValidatedToggle,activationCodeValidated} = props;
    const paddingSides = "8vw"
    const [visible,setvisible]= useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [requestedOS, setRequestedOS] = useState('ios');
    const inputRef = useRef();
    const isNavTop = navType === NAV_TYPE_TOP ? true : false
    const carouselBtn = {color:"transparent",background:"#FFFFFF",borderColor:"#FFFFFF",marginTop:"1rem"}
    const mode = utils.getColorContrast(headerNavColor)
    const history = useHistory();

    const routeChange = (destiny) =>{
        let path = `/main/page/${destiny}`;
        history.push(path);
    }

    const onSpanClick = (key) =>{
        setvisible(false)
        if (key!=='team'){
            routeChange(key)
        }else if (appLocation==='/app/views/about'||appLocation==='/app/views/home'){
            if (isMobile){
                scroller.scrollTo('team', {
                    smooth: 'easeInOutQuart'
                })
            }else{
                scroll.scrollToBottom();
            }

        }else{
            routeChange('about')
            setScrollDown(true)
        }
    }

    const onToggle = () => {
        if(!isMobile) {
            toggleCollapsedNav(!navCollapsed)
        } else {
            onMobileNavToggle(!mobileNav)
        }
    }
    const openInNewTab = (os) => {
        setIsModalVisible(true);
        setRequestedOS(os);
    }

    // const handleOk = () => {
    //     const url = requestedOS==='ios'?'https://testflight.apple.com/join/ZmUYSShS':'https://play.google.com/store/apps/details?id=com.euler.rebecaApp'
    //     const activation_code = inputRef.current.input.value;
    //     if (!activation_code.trim()) {
    //         setIsModalVisible(false);
    //         return
    //     }
    //     const API_BASE_URL = 'https://eulerinnovations.com/api/test/';
    //     fetch(API_BASE_URL+'code/claim', {
    //         method: "POST",
    //         headers: {
    //             Accept: 'application/json',
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify({
    //             data:{
    //                 code: activation_code,
    //                 validation_date: moment().format('YYYY-MM-DD'),
    //                 claim: false
    //             }
    //         })
    //     }).then(response => response.json()).then(data => {
    //         if(data.status==="success"){
    //             onActivationCodeValidatedToggle(true)
    //             window.open(url)
    //             history.push(`/main/page/help`)
    //         }
    //         else{
    //             message.loading("", 1).then(() => message.error("Invalid code", 1))
    //         }
    //     }).finally(()=>{
    //         setIsModalVisible(false)
    //     });
    // };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const getNavWidth = () => {
        console.log("movil2",isMobile)
        if(isNavTop || isMobile) {
            return '0px'
        }
        if(navCollapsed) {
            return `${SIDE_NAV_COLLAPSED_WIDTH}px`
        } else {
            return `${SIDE_NAV_WIDTH}px`
        }
    }
    const header = isMobile?
        { position: 'absolute', zIndex: 2, width: `100%`, background:"transparent",padding:"0% 6% 0%"}:
        { position: 'absolute', zIndex: 2, width: `100%`, background:"transparent",paddingLeft:paddingSides,paddingRight:paddingSides}

    return (
        <Header style={header}>
            <Flex justifyContent='between'>
                <a style={{paddingTop:"4vh"}}>
                    <img src={headerColor===HEADER_COLOR_LIGHT?logo:logo_dark} style={{maxHeight:!isMobile?"15vh":"9vh"}} onClick={()=>routeChange("home")}/>
                </a>
                <Flex justifyContent={'end'} alignItems={'center'}>
                    {/*{!isMobile?<Menu mode="horizontal" style={{background:"transparent",border:"none"}} >*/}
                    {/*    {navigationConfig.map( (dir) => (*/}
                    {/*        <Menu.Item key={dir.key} style={{paddingRight:"2vw"}}>*/}
                    {/*            <span style={{color:headerColor===HEADER_COLOR_LIGHT?"white":"black",fontSize:"1vw",fontFamily:'ITC Avant Garde Gothic Std Medium'}} onClick={()=>onSpanClick(dir.key)}>*/}
                    {/*                {injectIntl({id:dir.key})}*/}
                    {/*            </span>*/}
                    {/*        </Menu.Item>*/}
                    {/*    ))}*/}
                    {/*</Menu>:null}*/}
                    {/*<Button  style={{color:headerColor===HEADER_COLOR_LIGHT?"white":"black",background:"transparent",borderColor:headerColor===HEADER_COLOR_LIGHT?"white":"black",fontFamily:"ITC Avant Garde Gothic Std Medium"}} onClick={() => openInNewTab('android')} size={isMobile?"small":"large"}>ANDROID</Button>*/}
                    {/*<Button  style={{color:headerColor===HEADER_COLOR_LIGHT?"white":"black",background:"transparent",borderColor:headerColor===HEADER_COLOR_LIGHT?"white":"black",fontFamily:"ITC Avant Garde Gothic Std Medium",marginLeft:'4vw'}} onClick={() => openInNewTab('ios')} size={isMobile?"small":"large"}>IOS</Button>*/}
                    {/*{isMobile? <MenuOutlined style={{color:headerColor===HEADER_COLOR_LIGHT?"white":"black",fontSize:"200%",paddingLeft:"3vw"}} onClick={()=>setvisible(true)} />:null}*/}
                </Flex>
            </Flex>
            {/*<Drawer*/}
            {/*    placement="right"*/}
            {/*    closable={true}*/}
            {/*    onClose={()=>setvisible(false)}*/}
            {/*    visible={visible}*/}
            {/*    style={{zIndex:2,fontWeight:"bolder"}}*/}
            {/*>*/}
            {/*    <Menu mode="vertical" style={{background:"transparent",borderColor:"blue",textAlign:"center",paddingTop:"50%"}} >*/}
            {/*        {navigationConfig.map( (dir) => (*/}
            {/*            <Menu.Item key={dir.key}>*/}
            {/*                    <span style={{color:"white",fontSize:"200%",fontFamily:"ITC Avant Garde Gothic Std Medium"}} onClick={()=>onSpanClick(dir.key)}>*/}
            {/*                        {injectIntl({id:dir.key})}*/}
            {/*                    </span>*/}
            {/*            </Menu.Item>*/}
            {/*        ))}*/}
            {/*    </Menu>*/}
            {/*</Drawer>*/}

            {/*<Modal title="Insert your activation code" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>*/}
            {/*    <Input placeholder="bef27136-6d89-464c-a02e-aafa292ebb8d" ref={inputRef} />*/}
            {/*</Modal>*/}
        </Header>
    )
}

const mapStateToProps = ({theme}) => {
    const { navCollapsed, navType, headerNavColor, mobileNav, headerColor, appLocation,activationCodeValidated} =  theme;
    return { navCollapsed, navType, headerNavColor, mobileNav, headerColor, appLocation,activationCodeValidated}
};

export default connect(mapStateToProps,{toggleCollapsedNav, onMobileNavToggle, setScrollDown,onActivationCodeValidatedToggle})(HeaderNav);
