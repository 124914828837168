import React, { useState } from "react";
import { connect } from "react-redux";
import {Menu, Layout, Badge, Row, Col, Image, Button, Descriptions, Grid} from "antd";
import logo from "../../assets/images/logo.png";
import utils from "../../utils";
const { Footer } = Layout;
const { useBreakpoint } = Grid

export const PageFooter = (props) => {
    const screens = utils.getBreakPoint(useBreakpoint());
    const isMobile = !screens.includes('lg')
    const data = isMobile?
        {color:"white",fontSize:'4vw',fontFamily:"ITC Avant Garde Gothic Std Demi"}:
        {color:"white",fontSize:'1.5vw',fontFamily:"ITC Avant Garde Gothic Std Demi"};

    return (
        <Footer style={{background:"black",height:"50vh",padding:"8vw"}}>
            {isMobile?
                <div style={{marginBottom:'3vh'}} >
                    <text style={{color: "white", fontSize: "7vw",fontFamily:"ITC Avant Garde Gothic Std Demi"}}>Contact Us</text>
                </div>:null
            }
            <Row>
                <Col span={isMobile?24:10}>
                    <Descriptions
                        size="small"
                        layout="vertical"
                        colon={false}
                    >
                        <Descriptions.Item label="Phone" labelStyle={data} contentStyle={{color:"white",fontFamily:"ITC Avant Garde Gothic Std Book",fontSize:!isMobile?'0.9vw':'3vw'}}>+51 967064515</Descriptions.Item>
                        <Descriptions.Item label="Email" labelStyle={data} contentStyle={{color:"white",fontFamily:"ITC Avant Garde Gothic Std Book",fontSize:!isMobile?'0.9vw':'3vw'}}>contact@eulerinnovations.com</Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
        </Footer>
    )
}

const mapStateToProps = ({ theme}) => {
    const { navCollapsed, navType, headerNavColor, mobileNav } =  theme;
    return { navCollapsed, navType, headerNavColor, mobileNav}
};

export default connect(mapStateToProps,{})(PageFooter);
