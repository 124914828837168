import React, {useEffect} from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "../layouts/app-layout";
import AppLocale from "../lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';

function RouteInterceptor({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) => (children)}
        />
    );
}
export const Views = (props) => {
    const {locale,location} = props;
    //const currentAppLocale = AppLocale[navigator.language.split(/[-_]/)[0]];
    const currentAppLocale = AppLocale[locale];
    return (
        <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            <ConfigProvider locale={currentAppLocale.antd}>
                <Switch>
                    <Route exact path="/">
                        <Redirect to="/main"/>
                    </Route>
                    <Route path='/admin' component={() => {
                        window.location.href = 'https://rebeca.app/admin/';
                        return null;
                    }}/>
                    <RouteInterceptor path="/main">
                        <AppLayout location={location}/>
                    </RouteInterceptor>
                </Switch>
            </ConfigProvider>
        </IntlProvider>
    )
}

const mapStateToProps = ({theme}) => {
    const {locale} =  theme;
    return { locale }
};

export default connect(mapStateToProps,{})(Views);
