export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSED_NAV';
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const SIDE_NAV_STYLE_CHANGE = 'SIDE_NAV_STYLE_CHANGE';
export const NAV_TYPE_CHANGE = 'NAV_TYPE_CHANGE';
export const TOP_NAV_COLOR_CHANGE = 'TOP_NAV_COLOR_CHANGE';
export const HEADER_NAV_COLOR_CHANGE = 'HEADER_NAV_COLOR_CHANGE';
export const TOGGLE_MOBILE_NAV = 'TOGGLE_MOBILE_NAV';
export const SET_HEADER_COLOR = 'SET_HEADER_COLOR';
export const SET_APP_LOCATION = 'SET_APP_LOCATION';
export const SET_SCROLL_DOWN = 'SET_SCROLL_DOWN';
export const TOGGLE_CODE_VALIDATED = 'TOGGLE_CODE_VALIDATED';
