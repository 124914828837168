import {
    DashboardOutlined,
    AppstoreOutlined,
    FileTextOutlined,
    PieChartOutlined,
    EnvironmentOutlined,
    AntDesignOutlined,
    SafetyOutlined,
    StopOutlined,
    DotChartOutlined,
    MailOutlined,
    MessageOutlined,
    CalendarOutlined,
    BulbOutlined,
    InfoCircleOutlined,
    CompassOutlined,
    LayoutOutlined,
    DesktopOutlined,
    FileDoneOutlined,
    CommentOutlined,
    RobotOutlined,
    PlusCircleOutlined,
    FundOutlined,
    ShoppingCartOutlined,
    BookOutlined,
    FileUnknownOutlined,
    ProfileOutlined,
    StockOutlined,
    UserOutlined,
    GiftOutlined,
    VideoCameraAddOutlined,
    QuestionCircleOutlined,
    ContactsOutlined
} from '@ant-design/icons';

const dashBoardNavTree = [
    {
        key: 'project',
        path: '/app/views/project',
        name:"about",
        title: 'REBECA PROJECT',
        icon: PieChartOutlined,
        breadcrumb: false,
        submenu: []
    },
    {
        key: 'about',
        path: '/app/views/about',
        name:"project",
        title: 'ABOUT US',
        icon: StockOutlined,
        breadcrumb: false,
        submenu: []
    },
    /*
    {
        key: 'services',
        path: '/app/views/services',
        name:"services",
        title: 'SERVICES',
        icon: FileDoneOutlined,
        breadcrumb: false,
        submenu: []
    },
     */
    {
        key: 'team',
        path: '/app/views/about',
        name:"team",
        title: 'OUR TEAM',
        icon: UserOutlined,
        breadcrumb: false,
        submenu: []
    }
]

const navigationConfig = [
    ...dashBoardNavTree
]

export default navigationConfig;