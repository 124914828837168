import React, { Suspense } from "react";
import lazyWithRetry from "../../components/util-components/LazyWithRetry";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from '../../components/shared-components/Loading';

export const AppViews = ({match}) => {
    return (
        <Suspense fallback={<Loading cover="content"/>}>
            <Switch>
                <Route path={`${match.url}/page`} component={lazyWithRetry(() => import(`./pages`))} />
                <Redirect from={`${match.url}`} to={`${match.url}/page`} />
            </Switch>
        </Suspense>
    )
}

export default AppViews;
